import * as React from 'react';
import * as ReactDom from 'react-dom/client';

import {
  Css,
  FeatureAppLoader,
  FeatureHubContextProvider,
} from '@feature-hub/react';

import {
  FeatureAppEnvironment,
  FeatureAppManager,
  FeatureServices,
  Logger,
} from '@feature-hub/core';

import { ThemeProvider } from '@audi/audi-ui-react-v2';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { ThemeProvider as UnifiedThemeProvider } from '@oneaudi/unified-ui-react';

export interface FeatureAppAttributes {
  readonly baseUrl: string;
  readonly callbacks: string;
  readonly config: Record<string, unknown>;
  readonly css: Css[];
  readonly featureAppId: string;
  readonly serverSrc: string;
  readonly src: string;
}

export interface RenderFeatureAppOptions {
  readonly featureApp: Element;
  readonly featureAppAttributes: FeatureAppAttributes;
  readonly featureAppManager: FeatureAppManager;
  readonly iconBasePath?: string;
  readonly logger: Logger;
}

const beforeCreateInitTracking = (
  env: FeatureAppEnvironment<FeatureServices, Record<string, unknown>>,
): void => {
  const trackingService = env.featureServices[
    'audi-tracking-service'
  ] as TrackingServiceV2;
  if (trackingService) {
    trackingService.featureAppId = env.featureAppId;
  }
};

function hydrateFeatureApp(options: RenderFeatureAppOptions): ReactDom.Root {
  const {
    featureApp,
    featureAppAttributes,
    featureAppManager,
    iconBasePath,
    logger,
  } = options;

  const { baseUrl, config, css, featureAppId, serverSrc, src } =
    featureAppAttributes;

  return ReactDom.hydrateRoot(
    featureApp,
    <FeatureHubContextProvider value={{ featureAppManager, logger }}>
      <ThemeProvider theme={{ iconBasePath }}>
        <FeatureAppLoader
          {...(baseUrl && { baseUrl: baseUrl })}
          {...(config && { config: config })}
          {...(css && { css: css })}
          {...(featureAppId && { featureAppId: featureAppId })}
          {...(serverSrc && { serverSrc: serverSrc })}
          {...(src && { src: src })}
          beforeCreate={beforeCreateInitTracking}
        />
      </ThemeProvider>
    </FeatureHubContextProvider>,
    {
      onRecoverableError: (error, errorInfo) => {
        logger.error(`[${featureAppId}] ${error}${errorInfo.componentStack}`);
      },
    },
  );
}

function renderFeatureApp(options: RenderFeatureAppOptions): ReactDom.Root {
  const {
    featureApp,
    featureAppAttributes,
    featureAppManager,
    iconBasePath,
    logger,
  } = options;

  const { baseUrl, config, css, featureAppId, serverSrc, src } =
    featureAppAttributes;

  const root = ReactDom.createRoot(featureApp);

  root.render(
    <FeatureHubContextProvider value={{ featureAppManager, logger }}>
      <ThemeProvider theme={{ iconBasePath }}>
        <UnifiedThemeProvider theme={{ iconBasePath }}>
          <FeatureAppLoader
            {...(baseUrl && { baseUrl: baseUrl })}
            {...(config && { config: config })}
            {...(css && { css: css })}
            {...(featureAppId && { featureAppId: featureAppId })}
            {...(serverSrc && { serverSrc: serverSrc })}
            {...(src && { src: src })}
            beforeCreate={beforeCreateInitTracking}
          />
        </UnifiedThemeProvider>
      </ThemeProvider>
    </FeatureHubContextProvider>,
  );

  return root;
}

export {
  hydrateFeatureApp,
  renderFeatureApp,
  beforeCreateInitTracking as __beforeCreateInitTracking,
};
