import { getMetaContent, getPreviewToken } from '../feature-hub/page-content';

type NemoJwtTokenClaims = {
  'mandand-id': string;
  application: string;
  'market-id': string;
  language: string;
  'image-render-policy': string;
};

export const requiresEosImageHandshake = (head: HTMLHeadElement): boolean => {
  const preEosToken2Url = getMetaContent(
    head,
    'mediaservice-pre-eos-jwt2cookie',
  );
  if (preEosToken2Url) {
    const jwtPreviewToken = getPreviewToken(head);
    if (jwtPreviewToken) {
      const parsedToken = parseJwtContent(jwtPreviewToken);
      return parsedToken?.['image-render-policy'] === 'PreEos';
    }
  }

  return false;
};

export const applyPreEosImageHandshake = async (
  head: HTMLHeadElement,
): Promise<number> => {
  const preEosToken2Url = getMetaContent(
    head,
    'mediaservice-pre-eos-jwt2cookie',
  );
  const jwtPreviewToken = getPreviewToken(head);
  if (preEosToken2Url && jwtPreviewToken) {
    const fetchOptions: RequestInit = {
      body: JSON.stringify({
        cookieName: 'default',
        cookieValue: jwtPreviewToken,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    };
    const response = await fetch(preEosToken2Url, fetchOptions);
    return response.status;
  }
};

const parseJwtContent = (token: string): NemoJwtTokenClaims => {
  if (token) {
    const base64Url = token.split('.')[1];
    if (base64Url) {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload) as NemoJwtTokenClaims;
    }
  }
};
